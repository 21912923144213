import { Form, Formik } from 'formik';
import { bool, func } from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Button } from '../../button';
import { Input } from '../../form';
import './reset.scss';

const bem = new BEMHelper({
  name: 'auth-reset',
});

export const Reset = ({ withoutDetails, onReset }) => {
  const { t } = useTranslation();
  const [isSend, setIsSend] = useState(false);

  const onSubmit = useCallback(async (credentials, { setFieldError, setSubmitting }) => {
    try {
      await onReset(credentials);
    } catch (error) {
      setFieldError('general', error?.description || t('An error occurred.'));
      setSubmitting(false);
    }

    setIsSend(true);
  }, [onReset]);

  if (isSend) {
    if (withoutDetails) {
      return null;
    }

    return (
      <div {...bem()}>
        <div {...bem('details')}>
          <h2 {...bem('headline')}>{t('Reset password?')}</h2>
          <p {...bem('description')}>
            {t('The E-Mail was sent successfully – please check your inbox. If you have not received an E-Mail, please check your spam folder or try again.')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={{ email: '' }}>
      {({
        values,
        errors,
        touched,
        handleBlur,
      }) => (
        <Form {...bem()}>
          {!withoutDetails && (
            <div {...bem('details')}>
              <h2 {...bem('headline')}>{t('Reset password?')}</h2>
              <p {...bem('description')}>
                {t('Please give us your E-Mail address. We will send you an email that will allow you to reset your password.')}
              </p>
              {errors.general && (<p {...bem('error')} role="alert">{errors.general}</p>)}
            </div>
          )}

          <div {...bem('form')}>
            <Input
              label={t('E-Mail')}
              type="email"
              name="email"
              id="email"
              value={values.email}
              error={errors.email && touched.email && errors.email}
              onBlur={handleBlur}
              required
            />

            <Button
              {...bem('submit')}
              type="submit"
              title={t('Submit E-Mail address to request a password change')}
            >
              {t('Request password')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

Reset.propTypes = {
  withoutDetails: bool,
  onReset: func.isRequired,
};

Reset.defaultProps = {
  withoutDetails: false,
};
