import { canUseDOM } from 'exenv';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Sitemeta } from '../../components/core/sitemeta';
import { Login } from '../../components/common/auth/login';
import { Reset } from '../../components/common/auth/reset';
import { Details, Form, Layout } from '../../components/common/form';
import { MinimalHeading } from '../../components/headings/minimal';
import { capitalize } from '../../helpers/string';
import { resolve, query } from '../../helpers/urls';
import { useAuth } from '../../hooks/useAuth';
import { useNotification } from '../../hooks/useNotification';

const AUTH0_VERIFICATION_MESSAGE = 'Your email was verified. You can continue using the application.';

const TYPE_SSR = 'ssr';
const TYPE_LOADING = 'loading';
const TYPE_ERRORED = 'error';
const TYPE_MESSAGE = 'message';
const TYPE_VERIFICATION = 'verification';
const TYPE_LOGGED_OUT = 'logged-out';
const TYPE_LOGGED_IN = 'logged-in';
const TYPE_RESET = 'reset';

const useCallbackType = () => {
  const { isLoading, isAuthenticated, error } = useAuth();

  if (!canUseDOM) {
    return TYPE_SSR;
  }

  const { message } = query(window.location.href);

  if (message === TYPE_RESET) {
    return TYPE_RESET;
  }
  if (isLoading) {
    return TYPE_LOADING;
  }
  if (error) {
    return TYPE_ERRORED;
  }
  if (message === AUTH0_VERIFICATION_MESSAGE) {
    return TYPE_VERIFICATION;
  }
  if (message) {
    return TYPE_MESSAGE;
  }
  if (!isAuthenticated) {
    return TYPE_LOGGED_OUT;
  }

  return TYPE_LOGGED_IN;
};

const Contents = () => {
  const { t } = useTranslation();
  const {
    isAuthenticated, login, loginComplete, reset, openReset, openRequestAccess,
  } = useAuth();
  const { addNotification } = useNotification();
  const type = useCallbackType();

  useEffect(() => {
    switch (type) {
      case TYPE_VERIFICATION:
        addNotification('success', t('Account successfully verified.'));
        break;
      case TYPE_LOGGED_OUT:
        addNotification('success', t('Successfully signed out.'));
        navigate(resolve('home'));
        break;
      case TYPE_LOGGED_IN:
        addNotification('success', t('Successfully signed in.'));
        loginComplete();
        break;
      default:
        break;
    }
  }, [type]);

  const [isRequested, setRequested] = useState(false);
  const onRequestReset = useCallback(async (credentials) => {
    try {
      await reset(credentials);
      addNotification('success', t('The E-Mail was sent successfully – please check your inbox.'));
      setRequested(true);
    } catch (err) {
      addNotification('error', err?.description || t('An error occurred.'));
      throw err;
    }
  }, [reset, addNotification, setRequested]);

  if (!canUseDOM) {
    return null;
  }

  const { message, code } = query(window.location.href);

  switch (type) {
    case TYPE_RESET:
      return (
        <Layout>
          <>
            <Details
              headline={t('Password reset')}
              description={t('Please request a new password by filling the form to set your personal one. After you have set your new password, you can sign in.')}
            />
            <Form>
              <Reset withoutDetails onReset={onRequestReset} />
            </Form>
          </>
        </Layout>
      );
    case TYPE_VERIFICATION:
      return (
        <Layout>
          {!isRequested
            ? (
              <>
                <Details
                  headline={t('Account successfully verified')}
                  description={t('Your account is already protected with a generated password. Please request a new password by filling the form to set your personal one. After you have set your new password, you can sign in.')}
                />
                <Form>
                  <Reset withoutDetails onReset={onRequestReset} />
                </Form>
              </>
            )
            : (
              <>
                <Details
                  headline={t('Sign in')}
                  description={t('We have sent you an E-Mail to set your personal password. You can sign in here after.')}
                />
                <Form>
                  <Login withoutDetails onLogin={login} onResetPassword={openReset} />
                </Form>
              </>
            )}
        </Layout>
      );
    case TYPE_MESSAGE:
      return (
        <Layout>
          <Details
            headline={capitalize(code).replace('-', ' ') || t('Authentication')}
            description={message}
          />
          <Form>
            {!isAuthenticated && (
              <Login
                withoutDetails
                onLogin={login}
                onResetPassword={openReset}
                onRequestAccess={openRequestAccess}
              />
            )}
          </Form>
        </Layout>
      );
    case TYPE_LOADING:
      // @TODO: Add a nicer loading state
      return null;
    default:
      return null;
  }
};

const AuthCallbackPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Sitemeta title={t('Your account')} robots="noindex,follow" />
      <MinimalHeading headline={t('Your account')} />
      <Contents />
    </>
  );
};

export default AuthCallbackPage;
